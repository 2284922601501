<script lang='ts'>
  import { _ } from '@/helpers/i18n'
  import { clickOutside } from '@/helpers/mixHelpers'
  import { notifier } from '@/helpers/notifier'

  import Icon from '@/components/icons/Icon.svelte'
  import CloseButton from '@/components/ui/CloseButton.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  // if needed more functionalities we could use https://github.com/ellisonleao/sharer.js
  import ShareButton from './ShareButton.svelte'
  // Xing, Line

  export let url = location.href,
    title = document.title,
    desc = document.querySelector('meta[name="description"]')
  let displayShare = false

  function copyToClipboard (copyText: string) {
    navigator.clipboard.writeText(copyText)
    notifier.success($_('mix.copied'))
    displayShare = false
  }
</script>
<div class='share-button'>
  <FakeButton onClick={() => { displayShare = true }}>
    <Icon icon='ShareFat' weight='regular' />
  </FakeButton>
  <div class='content-wrapper' class:-hidden={!displayShare} on:click_outside={() => {displayShare = false}} use:clickOutside>
    <CloseButton closeVariant='absolute' onClick={() => { displayShare = false }} />
    <div class='share-buttons'>
      <ShareButton desc='{desc} {url}' {title} type='email' />
      <ShareButton {title} type='facebook' {url} />
      <ShareButton {title} type='twitter' {url} />
      <ShareButton type='linkedin' {url} />
      <ShareButton {title} type='reddit' {url} />
      <ShareButton {title} type='tumblr' {url} />
      <ShareButton {title} type='whatsapp' {url} />
      <!--
    <ShareButton type='xing' {title} {url} />
    <ShareButton type='line' {url} />
     -->
      <ShareButton {title} type='telegram' {url} />
      <ShareButton {title} type='vk' {url} />
    </div>
    <div class='copyToClipboard' role='button' tabindex='0' on:click={() => copyToClipboard(url)} on:keydown={() => {}}>
      <Icon icon='CopySimple' />
    </div>
  </div>
</div>
<style lang='scss'>
  .share-buttons {
    position: relative;
    cursor: pointer;
  }

  .content-wrapper {
    position: absolute;
    top: 4rem;
    right: 7rem;
    z-index: 10;
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-height: 7.2rem;
    padding: 1.6rem;
    background-color: var(--Base-White);
    border: 0.1rem solid var(--Gray-Dark);
    border-radius: 2.4rem;

    &.-hidden {
      display: none;
    }

    > .copyToClipboard {
      padding-top: 1.6rem;
      border-top: 0.1rem solid var(--Gray-Dark);
      cursor: pointer;
    }
  }

  .share-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.6rem;
    justify-content: space-between;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
</style>
